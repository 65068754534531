// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyC9zoIaeZ_WK2gD8SNWsFK_PlGkRiBPk0A",
  authDomain: "thoughtshard-waitlist.firebaseapp.com",
  projectId: "thoughtshard-waitlist",
  storageBucket: "thoughtshard-waitlist.firebasestorage.app",
  messagingSenderId: "823469707716",
  databaseURL: "https://thoughtshard-waitlist-default-rtdb.firebaseio.com",
  appId: "1:823469707716:web:9704fa6a3a9b500b639491"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);