import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore';
import { firebaseConfig } from '../config/FirebaseConfig';
import BackgroundVideo from "../videos/thoughtshard-bg-video.mp4";

initializeApp(firebaseConfig);
const db = getFirestore();

function ThoughtShard() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const INITIAL_SPOTS = 100;  // Easily configurable waitlist size
  const [count, setCount] = useState(INITIAL_SPOTS);
  const [successMessage, setSuccessMessage] = useState('');

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'waitlist'));
        setCount(INITIAL_SPOTS - querySnapshot.size); // Calculate remaining spots
      } catch (error) {
        console.error('Error fetching count:', error);
      }
    };

    fetchCount();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting form...', email); // Debug log

    if (!isValidEmail(email)) {
      setSuccessMessage('Please enter a valid email address');
      return;
    }

    try {
      const emailData = {
        email: email,
        timestamp: new Date().toISOString(),
        source: 'waitlist'
      };

      await addDoc(collection(db, 'waitlist'), emailData);

      setSubmitted(true);
      setEmail('');
      const newCount = Math.max(0, count - 1);
      setCount(newCount);
      setSuccessMessage(newCount > 0
        ? `Welcome aboard, your seat is secured. ${newCount} spot${newCount === 1 ? '' : 's'} remain${newCount === 1 ? 's' : ''}.`
        : "Welcome aboard, we'll be in touch.");
    } catch (error) {
      console.error('Error adding document:', error);
      setSuccessMessage('Oops! Something went wrong. Please try again.');
    }
  };

  return (
    <div className="relative h-screen w-full overflow-hidden font-round">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 min-h-full min-w-full object-cover z-0"
      >
        <source src={BackgroundVideo} type="video/mp4" />
      </video>

      <div className="absolute inset-0 bg-black/25 z-10" />

      <div className="relative z-20 h-full flex flex-col items-center justify-center px-4 text-white">
        <h1 className="text-5xl md:text-6xl text-glow tracking-tight mb-4">ThoughtShard</h1>
        <p className="text-xl md:text-2xl mb-8 font-bold">See yourself think.</p>

        <form onSubmit={handleSubmit} className="w-full max-w-md bg-black/50 rounded-lg p-4">
          <p className="text-lg mb-4 text-center">
            {count > 0
              ? `Only ${count.toLocaleString()} spots remain for early access.`
              : 'All early access seats are now claimed. Join our waitlist for the next drop.'}
          </p>
          <div className="flex flex-col md:flex-row gap-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="flex-grow px-4 py-3 rounded-lg bg-white/10 backdrop-blur-md border border-white/20 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
            />
            <button
              type="submit"
              className="px-6 py-3 bg-white/10 hover:bg-white/20 backdrop-blur-md rounded-lg border border-white/20 transition-colors"
            >
              Join Waitlist
            </button>
          </div>

          {submitted && (
            <div className="mt-4 text-center">
              <p className="animate-fade-in">
                {successMessage}
              </p>
            </div>
          )}
        </form>

        <div className="absolute bottom-8 text-sm opacity-75 text-glow">
          <p>Copyright 2025 - An Impact Moonshots Production</p>
        </div>
      </div>
    </div>
  );
}

export default ThoughtShard;
