import Logo from "../images/logo.png";
import LandingImage from "../images/landing.png";
import HomeImage from "../images/astronaut-planet.png";
import Fade from 'react-reveal/Fade';

function Home() {
  const handleSubscribeClick = () => {
    window.open("https://impactmoonshots.substack.com", '_blank').focus();
  }

  return (
    <div data-theme="light" className="flex flex-col w-full h-full">
      {/* Hero */}
      <div className="p-9 sm:p-12 flex flex-col bg-gradient-to-b from-[#8000FF] to-[#19E0ED] w-full min-h-screen h-full">
        <div className="w-full flex justify-between">
          {/* Logo */}
          <div className="flex flex-row space-x-2">
            <img alt="Glowing crescent moons side by side" src={Logo} className="w-20 h-10"/>
            <header className="uppercase text-white mt-1 text-2xl text-glow font-righteous">
              Impact Moonshots
            </header>
          </div>
        </div>

        <div className="flex flex-col items-center h-full justify-between">
          <div className="flex flex-col items-center">
            <Fade up>
              <div className="lg:w-1/2 mt-8 text-white font-mono text-3xl leading-[48px] sm:text-5xl sm:leading-[64px] text-glow uppercase text-left sm:text-center">
                Moonshots for the world’s most overlooked problems.
              </div>
              <div className="font-round text-white text-xl sm:mt-8 sm:text-2xl sm:w-full text-left sm:text-center mt-4">
                What would you create for humanity if you could not fail?
              </div>
            </Fade>

            <button
              className="mt-8 mr-auto sm:mr-0 sm:mt-10 btn btn-secondary uppercase font-mono sm:text-lg rounded-3xl"
              onClick={handleSubscribeClick}
            >
              Follow our first launch
            </button>
          </div>

          <img alt="astronaut landing on vibrant planet" className="mt-8 sm:mt-10 w-full sm:w-full lg:w-2/3" src={HomeImage}/>
        </div>
      </div>

      {/* About */}
      <div className="p-9 flex flex-col bg-gradient-to-b to-[#BE6CFF] from-[#19E0ED] h-full w-full min-h-screen items-center justify-center">
        <Fade up>
          <div className="md:leading-[64px] lg:w-2/3 text-white font-mono text-3xl leading-[48px] sm:text-5xl text-glow uppercase text-center">
            Innovation that doesn't wait for permission.
          </div>
          <div className="lg:w-1/2 flex flex-col space-y-8 font-round text-white text-xl sm:text-2xl text-center mt-12">
            <span>We’re crafting a new vision for the world,
            where social change meets imagination and doing good feels like magic.
            </span>

            <span>Join us in writing a new story for Silicon Valley.</span>
          </div>
        </Fade>

        <button
          className="mx-auto mt-10 w-fit btn btn-secondary uppercase font-mono sm:text-lg rounded-3xl"
          onClick={handleSubscribeClick}
        >
          Follow our first moonshot
        </button>
      </div>

      {/* First moonshot */}
      <div className="p-9 flex justify-center items-center flex-col bg-gradient-to-b from-[#BE6CFF] to-[#8000FF] via-[#19E0ED] lg:h-full w-full min-h-screen">
        <Fade up>
          <div className="lg:w-1/2 mt-6 text-white font-mono sm:text-5xl text-3xl text-glow uppercase text-center">
            Stay tuned for our first landing.
          </div>
          <div className="lg:w-1/2 mx-auto flex flex-col space-y-8 font-round text-white text-xl sm:text-2xl text-center mt-10">
            Coming soon to platforms near you.
          </div>
        </Fade>

        <button
          className="mt-10 mx-auto w-fit btn btn-secondary uppercase font-mono sm:text-lg rounded-3xl"
          onClick={handleSubscribeClick}
        >
          Follow our journey
        </button>
        <img alt="astronaut approaching glowing moon" className="mt-auto w-full sm:w-2/3 lg:w-[860px] mx-auto lg:mx-0" src={LandingImage}/>
        <div className="text-gray-300 font-round text-xs mt-auto">Copyright 2024 - Impact Moonshots</div>
      </div>
    </div>
  );
}

export default Home;
